@media only screen and (min-width: 300px)and (max-width: 768px){
    .desktop-arrows{
      display:none !important
    }
    .mobile-arrows{
      display:block
    }
  }

@media only screen and (min-width: 769px){
    .desktop-arrows{
      display:block 
    }
    .mobile-arrows{
      display:none
    }
  }