/* * {
  margin: 0;
  padding: 0;
}

body {
  background-color: #b5fac7;
} */
/* 
.pages {
  color: #316685;
  text-align: center;
  font-size: calc(1.5rem + 2vw);
  margin-top: 10%;
  
 
} */

.navbar {
  /* background-color: #1f5156; */
  background-color: #fff;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 20;
}

.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  /* max-width: 1500px; */
}

.main-container{
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background-color:rgba(0,0,0,0.3);
}

.nav-logo {
  color: #f5b921;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  flex-grow: 1;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
  justify-content: center;
}


.fa-code {
  margin-left: 1rem;
}

/* .nav-item {
  line-height: 40px;
  margin-right: 1rem;
} */

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

/* .nav-item:hover:after {
  width: 100%;
  background: #5cdffe;
} */

/* .nav-item .active {
  color: #ffdd40;
  border: 1px solid #ffdd40;
} */

.nav-icon {
  display: none;
}

@media screen and (max-width: 768px){
  .user-profile{
    display: block;
  }
  .three-dot-menu-icon{
    display: none;
  }
}

@media screen and (min-width: 769px){
  
  .user-profile{
    display: block;
  }

  .three-dot-menu-icon{
    display: none;
  }

}


@media screen and (max-width: 910px){
  .nav-links {
    color: #fff;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    border-bottom: 3px solid transparent;
  }
  
  .hide-cart{
    display: none;
  }
  
  .hide-cart1{
    display: flex;
  }
}

@media screen and (min-width: 1259px){
  .nav-links {
    color: black;
    text-decoration: none;
    /* padding: 0.5rem 1rem; */
    height: 100%;
    border-bottom: 3px solid transparent;
  }
  .nav-links:hover{
    color: black;
  }
  .hide-cart1{
    display: none;
  }
  .taste-test-color{
    color: #000 !important;
  }
  .menu-profile{
    background-color: #006C94;
    color: white;
  }

}



@media screen and (max-width: 1260px) {
  
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* border-top: 1px solid #fff; */
    position: absolute;
    top: 72px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
    align-items: stretch;
  }

  .nav-menu.active {
    /* background: #1f5156; */
    background: #D6F1FB;
    left: -10px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 9999;
    width: 100%;
    line-height: 2rem;
  }
  /* .nav-item .active {
    color: #fff;
    border: none;
  } */

  .nav-links {
    padding: 0.5rem;
    width: 100%;
    display: table;
  }

  .nav-icon {
    display: block;
    /* position: absolute; */
    top: 0;
    right: 0;
    transform: translate(-100%, 7%);
    font-size: 3.0rem;
    cursor: pointer;
    color: #006C94;
    padding-bottom: 17px;
    /* margin-right: -24px; */
  }

  .home-marging{
   margin-top: 20px;
  }
  .artist-marging{
    /* margin-top: -7px; */
  }
  .taste-test-color{
    color: #fff !important;
  }

  .menu-profile{
    background-color: #006C94;
    color: white;
  }

  .hamburger-menu-color{
    color: #006C94;
  }
}


@media all and (max-width: 958px){
  #deskview{
    display:none;
  }
  
}

@media all and (min-width: 959px){
  #mobileview{
    display:none;
  }
}
