

@media only screen and (min-width: 200px)and (max-width: 1150px){
    .menu-hide{
        display: block;
    }
  }
  @media only screen and (min-width: 1151px){
    .menu-hide{
        display: none;
    }
  }