.product {
    /* margin: 50px auto;
    width: 280px;
    height: 370px;
    background: white;
    border: 1px solid #333; */
    position: relative;
    /* z-index: 90; */
  }
  .ribbon-wrapper {
    width: 85px;
    height: 88px;
    overflow: hidden;
    position: absolute;
    top: -5px;
    /* left: -1px; */
    
  }
  .ribbon {
    font: bold 15px sans-serif;
    color: #333;
    text-align: center;
    -webkit-transform: rotate(-45deg);
    -moz-transform:    rotate(-45deg);
    -ms-transform:     rotate(-45deg);
    -o-transform:      rotate(-45deg);
    position: relative;
    padding: 7px 0;
    top: 15px;
    left: -30px;
    width: 120px;
    background-color: #fa3a00;
    color: #fff;
  }
  