/* * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  
  body {
    font-family: Roboto, Helvetica, Arial, sans-serif;
  } */
  
  .navbar {
    font-size: 18px;
    /* background-image: linear-gradient(260deg, #2376ae 0%, #c16ecf 100%); */
    /* background-color: linear-gradient(260deg, #fff 0%, #000 100%); */
    /* border: 1px solid rgba(0, 0, 0, 0.2); */
    background-color: white;
    padding-bottom: 10px;
    height: 72px;
    margin-right: -22px;
  }

  @media screen and (max-width: 767px) {
    .main-nav {
        list-style-type: none;
        display: none;
        background-color: black;
        margin-top: -7px;
        width: 100%;
        line-height: 1.8;
        margin-left: -10px;
      }
      .menu-color{
        color: white!important;
      }
      .menu-btn{
        background-color: #006C94!important;
        /* color: #006C94!important; */
        color: white!important;
      }
      .menu-cart{
        border-radius: 20px;
        padding: 10px;
        background-color: white;
      }
      
  }
  @media screen and (min-width: 768px) {
    .main-nav {
        display: flex;
        margin-right: 30px;
        flex-direction: row;
        justify-content: flex-end;
        
      }
      .menu-color{
        color: black;
      }
      /* .menu-btn{
        background-color: white!important;
        color: black!important;
        justify-content: center;
      
      }
      .menu-btn:hover{
        background-color: black!important;
        color: white!important;
      } */
      .menu-cart{
       
        padding: 0px 20px;
        
      }
      
  }
  @media screen and (min-width: 957px) {
    .menu-btn{
      background-color: #006C94!important;
      color: white!important;
      justify-content: center;
    
    }
    .menu-btn:hover{
      background-color: white!important;
      color: #006C94!important;
    }
    .nav-links {
      margin-left: 20px;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 956px){
    .menu-btn{
      background-color: white!important;
      color: black!important;
      justify-content: center;
    
    }
    .menu-btn:hover{
      background-color: black!important;
      color: white!important;
    }
  }

  
  .nav-links,
  .logo {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.7);
  }
  
  .main-nav li {
    text-align: center;
    margin: 15px auto;
  }
  
  .logo {
    display: inline-block;
    font-size: 22px;
    margin-top: 10px;
    margin-left: 20px;
  }
  
  .navbar-toggle {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
    /* color: rgba(255, 255, 255, 0.8); */
    color: black;
    font-size: 24px;
  }
  
  #chkToggle {
    display: none;
  }
  
  #chkToggle:checked + ul.main-nav {
    display: block;
  }
  
  @media screen and (min-width: 768px) {
    .navbar {
      display: flex;
      justify-content: space-between;
      padding-bottom: 0;
      height: 70px;
      align-items: center;
    }
  
    #chkToggle:checked + ul.main-nav {
      display: flex;
    }
  
    /* .main-nav {
      display: flex;
      margin-right: 30px;
      flex-direction: row;
      justify-content: flex-end;
    } */
  
    .main-nav li {
      margin: 0;
    }
  
    .nav-links {
      text-align: center;
    }
  
    .logo {
      margin-top: 0;
    }
  
    .navbar-toggle {
      display: none;
    }
  
    /* .logo:hover,
    .nav-links:hover {
      color: rgba(255, 255, 255, 1);
    } */
  }
  .login-circle-btn{
    border-width: 0px;
    font-family: Arial !important;
    border-style: solid;
    color: rgb(90, 95, 125);
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border-radius: 40px;
    padding: 0px 20.5px;
    height: 48px;
    font-weight: 500;
    box-shadow: 0px 0px;
  }