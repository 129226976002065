/* #root {
    display: flex;
    align-items: center;
  }
   */
/* 
   .checkout .vertical::before{
    height: 100%;
    width: 50%;
    background: #fff;
    position: fixed;
    content: " ";
    top: 0px;
    right: 0;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-transform-origin: right;
    -ms-transform-origin: right;
    transform-origin: right;
    -webkit-box-shadow: 10px 0 4px -4px rgba(250, 250, 250, 1) inset;
    -moz-box-shadow: 10px 0 4px -4px rgb(250, 250, 250, 1) inset;
    box-shadow: 10px 0 14px -4px rgb(250, 250, 250, 1) inset;
   }
      */
  
   /* .checkout form { */
    /* width: 40vw;
    align-self: center; */
    /* box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
      0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07); */
      /* box-shadow: 15px 0 30px 0 rgba(0,0,0,.18); */
       /* -webkit-box-shadow: 7px 0 4px -4px rgb(0, 0, 0 ,.18) inset;
          -moz-box-shadow: 7px 0 4px -4px rgb(0, 0, 0 ,.18) inset;
          box-shadow: 7px 0 4px -4px rgb(0, 0, 0 ,.18) inset; */
    /* border-radius: 0px; */
    /* margin-top: 25px; */
    /* margin-left: 50px!important; */
    /* //margin-left: 45px; */
  /* } */

  @media all and (max-width: 1208px){
    #mobile_cart_item_close{
      display:show;
    }
    #desktop_cart_item_close{
      display:none;
    }
  }
  
  @media all and (min-width: 1208px){
    #mobile_cart_item_close{
      display:none;
    }
    #desktop_cart_item_close{
      display:show;
    }
  }

  
  .checkout input {
    border-radius: 6px;
    margin-bottom: 6px;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    font-size: 16px;
    width: 100%;
    background: white;
    box-sizing: border-box;
  }
  
  
  .checkout .result-message  {
    line-height: 22px;
    font-size: 16px;
  }
  
  .checkout .result-message a {
    color: rgb(89, 111, 214);
    font-weight: 600;
    text-decoration: none;
  }
  
  .checkout .hidden {
    display: none;
  }
  
  .checkout #card-error {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    margin-top: 12px;
    text-align: center;
  }
  
  .checkout #card-element {
    border-radius: 4px 4px 4px 4px;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    width: 100%;
    background: white;
  }
  
  .checkout #payment-request-button {
    margin-bottom: 32px;
  }
  
  /* Buttons and links */
  .checkout button {
    background: #5469d4;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 4px 4px 4px 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
  }

  .checkout .shipping-button{
    font-family: Arial, sans-serif;
    color: #000;
    border-radius: 4px 4px 4px 4px;
    border: 0;
    padding: 8px 16px;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
  }

  .checkout button:hover {
    filter: contrast(115%);
  }
  
  .checkout button:disabled {
    opacity: 0.5;
    cursor: default;
  }
  .cart_item_title{    
    font-family: Melodrama; 
    font-weight: 600;
    font-size: 20px;
  }

  @media only screen and (min-device-width : 360px) and (max-device-width : 476px){
    .cross_icon {
      position: absolute;
      top: 24px;
      right: 7px;
    }

    .cart_item_title{
      font-family: Melodrama; 
      font-weight: 600;
      font-size: 18px;
    }
  }
  
  /* spinner/processing state, errors */
  .checkout .spinner,
  .checkout .spinner:before,
  .checkout .spinner:after {
    border-radius: 50%;
  }
  
  .checkout .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  
  .checkout .spinner:before,
  .checkout .spinner:after {
    position: absolute;
    content: "";
  }
  
  .checkout .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }
  
  .checkout .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }
  
  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @media only screen and (max-width: 600px) {
    .checkout form {
      width: 80vw;
    }
  }

  /* @media only screen and (max-width : 1519px){
    .cust-height{height:100%}
  }

  @media only screen and (min-device-width : 1520px){
    .cust-height{height:100vh}
  } */

  @media all and (min-width: 1200px) and (max-width: 1399px){
    .cust-height{height:100%!important;}
  }
  
  @media all and (min-width: 1400px){
    .cust-height{height:100vh!important;}
  }


  @media only screen and (min-device-width : 1400px){
    .home-page-height{margin-bottom:20vh}
  }
  @media only screen and (min-device-width : 50px) and (max-device-width : 1399px){
    .home-page-height{margin-bottom:15vh}
  }

  /* @media only screen and (max-device-width : 768px){
    .home-page-height{margin-bottom:30vh}
  } */
  
/* 
  @media only screen and (max-width: 600px){
    .logo-size{
      width: 105px;
      margin-top: -60px;
    }
  } */

  /* @media only screen and (max-width: 1000px){
    
    
    .material-info{
      display: none;
    }
  }

  @media only screen and (min-width: 1001px){
    
    .material-info{
      display: block;
    }
  } */



  @media only screen and (max-width: 768px){

    .category_container{
      margin-top: 5px;
    }

    .logo-size{
      width: 105px;
      margin-top: -60px;
    }
    .banner-width{
      height: 50vh;
      /* margin-top: 72px;
      width: 100%; */
    }
    .banner-title{
      font-size: 20px;
    }
    .banner-title1{
      font-size: 30px;
    }
    /* .slider-img{
      height: 300px;
    } */
    .heading{
      font-size: 20px;
      line-height: 30px;
      padding-bottom: 35px;
      margin-left: 30px;
      margin-right: 30px;
    }
    /* .work-img{
      height: 300px;
    } */
    .footer-style{
      width: fit-content;
    }
    .banner-paragrap{
      font-size: 11px;
      /* color: black; */
      margin-left: 20px;
      margin-right: 20px;
      text-align: center;
    }
    .banner-button{
      font-size: 7px;
      height: 18px;
      margin-top:11px
    }
    .middle-content{
      font-size: 40px;
      font-weight: 600;
      color: black;
    }
    
    .slider-banner{
      display: block;
    }
    .gallery-slider-banner{
      display: block;
    }
    .slider-banner1{
      display: none;
    }
    .artist-collector-data{
      padding-right: 25px;
    }
    .model-heading{
      margin-top: 15px;
    }
    .work-title{
      font-size: 15px;
    }
    .work-price{
      font-size: 15px;
    }
    .signup-with-google{
      font-size: 11px; 
    }
    .subtotal-tbl{
      width: 98%;
    }
    .work-modal{
      /* padding-top: 25px; */
      padding-top: 0px;
      padding-bottom: 30px;
    }
  }


  



  @media only screen and (min-width: 769px){

    /* .cust-home {
      min-height: calc(100vh - 60px);
    } */
    .category_container{
      margin-top: -15px;
    }
    
    .heading{
      font-size: 25px;
      line-height: 40px;
    }
    .work-img{
      height: 450px;
    }
    .banner-width{
      height: 70vh;
      /* width: 100%;
      margin-top: 72px; */
    }
    .banner-title{
      font-size: 50px;
    }
    .banner-title1{
      font-size: 60px;
    }
    .banner-paragrap{
      font-size: 18px;
      /* color: black; */
      margin-left: 20px;
      margin-right: 20px;
      text-align: center;
    }
    .banner-button{
      font-size: 13px;
      height: 35px;
      margin-top:15px;
    }
    .middle-content{
      font-size: 40px;
      font-weight: 600;
      color: black;
    }
    .slider-banner1{
      display: block;
    }
    .slider-banner{
      display: none;
    }
    
    .gallery-slider-banner{
      display: none;
    }
    .artist-collector-data{
      padding-right: 60px;
    }
    .work-title{
      font-size: 20px;
    }
    .work-price{
      font-size: 23px;
    }
    .subtotal-tbl{
      width: 98%;
    }
    .work-modal{
      /* padding-right: 25px; */
      padding-right: 0px;
    }
    
  }



  /** Work Slider Image Screen Resolution Media Query Start**/
 
  @media only screen and (max-width: 375px){
    
    .slider-banner-width{
      width: 90%;
    }
    .taste-quiz-slider-banner-width{
      width: 90%;
    }
    .no-works-image{
      height: 250px;
    }
    .no-works-title{
      font-size: 20px;
      padding-top: 70px;
    }
    .no-taste-quiz{
      font-size: 20px;
      padding-top: 30px;
      padding-bottom: 30px;
    }
    .no-filter-heading{
      font-size: 50px;
      padding-top: 45px;
    }
    /* .slider-img{
      height: 150px;
    } */

  }
   
   @media only screen and (min-width: 351px)and (max-width: 500px){
    
    .slider-banner-width{
      width: 100%;
    }
    .taste-quiz-slider-banner-width{
      width: 100%;
    }
    /* .slider-img{
      height: 200px;
    } */
    .no-works-image{
      height: 300px;
    }
    .no-works-title{
      font-size: 22px;
      padding-top: 90px;
    }
    .no-taste-quiz{
      font-size: 22px;
      padding-top: 30px;
      padding-bottom: 30px;
    }
    .no-filter-heading{
      font-size: 50px;
      padding-top: 45px;
    }

  }


  @media only screen and (min-width: 501px)and (max-width: 666px){
    .slider-banner-width{
      width: 87%;
    }
    .taste-quiz-slider-banner-width{
      width: 87%;
    }
    /* .slider-img{
      height: 300px;
    } */

    .no-works-image{
      height: 350px;
    }
    .no-works-title{
      font-size: 25px;
      padding-top: 110px;
    }
    .no-taste-quiz{
      font-size: 30px;
      padding-top: 80px;
      padding-bottom: 50px;
    }
    .no-filter-heading{
      font-size: 50px;
      padding-top: 80px;
    }

  }

  @media only screen and (min-width: 667px)and (max-width: 768px){
    
    .slider-banner-width{
      width: 72%;
    }
    .taste-quiz-slider-banner-width{
      width: 72%;
    }
    .no-works-image{
      height: 380px;
    }
    .no-works-title{
      font-size: 30px;
      padding-top: 110px;
    }
    .no-taste-quiz{
      font-size: 35px;
      padding-top: 60px;
      padding-bottom: 50px;
    }
    /* .slider-img{
      height: 320px;
    } */
    .no-filter-heading{
      font-size: 50px;
      padding-top: 80px;
    }

  }
  @media only screen and (min-width: 769px)and (max-width:1050px){
    .adjust-filter-for-virtcle{
      margin-left: -65px !important;
    }
  }  

  @media only screen and (min-width: 769px)and (max-width:900px){
    
    .slider-banner-width{
      width: 60%;
    }
    .taste-quiz-slider-banner-width{
      width: 60%;
    }
    /* .no-taste-quiz-image{
      height: 350px;
    } */
    /* .slider-img{
      height: 350px;
    } */
    .no-works-image{
      height: 400px;
    }
    .no-works-title{
      font-size: 30px;
      padding-top: 120px;
    }
    .no-taste-quiz{
      font-size: 35px;
      padding-top: 60px;
      padding-bottom: 50px;
    }
    .no-filter-heading{
      font-size: 50px;
      padding-top: 80px;
    }

  }

  @media only screen and (min-width: 901px)and (max-width:1024px){
    
    .slider-banner-width{
      width: 60%;
    }
    .taste-quiz-slider-banner-width{
      width: 60%;
    }
    /* .no-taste-quiz-image{
      height: 350px;
    } */
    .no-works-image{
      height: 400px;
    }
    .no-works-title{
      font-size: 40px;
      padding-top: 120px;
    }
    .no-taste-quiz{
      font-size: 35px;
      padding-top: 80px;
      padding-bottom: 30px;
    }
    /* .slider-img{
      height: 400px;
    } */
    .no-filter-heading{
      font-size: 55px;
      padding-top: 90px;
    }

  }

  @media only screen and (min-width: 1025px)and (max-width:1440px){
    
    .slider-banner-width{
      width: 55%;
    }
    .taste-quiz-slider-banner-width{
      width: 55%;
    }
    /* .no-taste-quiz-image{
      height: 350px;
    } */
    /* .slider-img{
      height: 500px;
    } */
    .no-works-image{
      height: 500px;
    }
    .no-works-title{
      font-size: 50px;
      padding-top: 130px;
    }
    .no-taste-quiz{
      font-size: 35px;
      padding-top: 80px;
      padding-bottom: 50px;
    }
    .no-filter-heading{
      font-size: 70px;
      padding-top: 150px;
    }


  }

  @media only screen and (min-width: 1441px)and (max-width:2560px){
    
    .slider-banner-width{
      width: 50%;
    }
    .taste-quiz-slider-banner-width{
      width: 50%;
    }
    /* .no-taste-quiz-image{
      height: 350px;
    } */
    .no-taste-quiz-image{
      height: 350px;
    }
    .no-works-image{
      height: 650px;
    }
    .no-works-title{
      font-size: 60px;
      padding-top: 200px;
    }
    .no-taste-quiz{
      font-size: 35px;
      padding-top: 80px;
      padding-bottom: 50px;
    }
    /* .slider-img{
      height: 600px;
    } */
    .no-filter-heading{
      font-size: 65px;
      padding-top: 150px;
    }

  }

  @media only screen and  (min-width:2561px){
    
    .slider-banner-width{
      width: 50%;
    }
    .taste-quiz-slider-banner-width{
      width: 50%;
    }
    /* .no-taste-quiz-image{
      height: 350px;
    } */
    .no-works-image{
      height: 550px;
    }
   
    .no-works-title{
      font-size: 60px;
      padding-top: 200px;
    }
    .no-taste-quiz{
      font-size: 35px;
      padding-top: 80px;
      padding-bottom: 50px;
    }
    .no-filter-heading{
      font-size: 70px;
      padding-top: 150px;
    }
    /* .slider-img{
      height: 600px;
    } */

  }

  /**Work Slider Image Screen Resolution Media Query End**/

  /*Taste Quiz Slider Container And Image Resolution Media Query Start*/
  @media only screen and (min-width: 251px)and (max-width: 768px){
    .taste_quiz_container{
      width: 70%;
      margin: 0 auto
    }
    .empty_queue_img{
      width: 100% !important;
    }
  }
  @media only screen and (min-width: 769px){
    .taste_quiz_container{
      width: 30%;
      margin: 0 auto
    }
  }

  @media only screen and (min-width: 251px)and (max-width: 768px){
    .taste_quiz_slider_images{
      width: 100% !important;
      align-items: center;
    }
  }

  .taste_quiz_slider_images{
    align-items: center;
  }

  

  /* .zoom-in-zoom-out {
    animation: zoom-in-zoom-out 1s ease-out ;
  }
  
  @keyframes zoom-in-zoom-out {
    0% {
      transform: scale(0, 0);
    }
    25% {
      transform: scale(0.2, 0.2);
    }
    50% {
      transform: scale(0.5, 0.5);
    }
    75% {
      transform: scale(0.7, 0.7);
    }
    100% {
      transform: scale(1, 1);
    }
  } */

  .fade-in-image {
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;
  }
  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  

  @media only screen and (max-width: 768px){
    .filter-icon-hide{
      display: none !important;
    }
    .filter-icon-hide1{
      display: none;
    }
  }
  @media only screen and (min-width: 769px){
    .filter-icon-hide1{
      display: block;
      float: right;
    }
  }

  
  
   /*Taste Quiz Slider Container And Image Resolution Media Query Start*/


   .box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
    



  @media only screen and (max-width: 900px){
    .desktop-horizontal-banner-width{
      width:85vw
    }
  }

  @media only screen and (min-width: 901px)and (max-width: 1040px){
    .desktop-horizontal-banner-width{
      width:75vw
    }
  }

  @media only screen and (min-width: 1041px)and (max-width: 1300px){
    .desktop-horizontal-banner-width{
      width:60vw
    }
  }

  @media only screen and (min-width: 1301px){
    .desktop-horizontal-banner-width{
      width:62vw
    }
  }

  

  @media only screen and (min-width: 300px)and (max-width: 600px){
    .desktop-vertical-banner-width{
      width:63vw
    }
  }

  @media only screen and (min-width: 601px)and (max-width: 768px){
    .desktop-vertical-banner-width{
      width:60vw
    }
  }

  @media only screen and (min-width: 769px)and (max-width: 900px){
    .desktop-vertical-banner-width{
      width:75vw
    }
  }

  @media only screen and (min-width: 901px)and (max-width: 1040px){
    .desktop-vertical-banner-width{
      width:75vw
    }
  }

  @media only screen and (min-width: 1041px)and (max-width: 1300px){
    .desktop-vertical-banner-width{
      width:80vw
    }
  }

  @media only screen and (min-width: 1301px){
    .desktop-vertical-banner-width{
      width:100vh
    }
  }

  /*----*/

  @media only screen and (min-width: 300px)and (max-width: 600px){
    .desktop-square-banner-width{
      width:70vw
    }
  }

  @media only screen and (min-width: 601px)and (max-width: 768px){
    .desktop-square-banner-width{
      width:65vw
    }
  }

  @media only screen and (min-width: 769px)and (max-width: 900px){
    .desktop-square-banner-width{
      width:60vw
    }
  }

  @media only screen and (min-width: 901px)and (max-width: 1040px){
    .desktop-square-banner-width{
      width:43vw
    }
  }

  @media only screen and (min-width: 1041px)and (max-width: 1300px){
    .desktop-square-banner-width{
      width:45vw
    }
  }

  @media only screen and (min-width: 1301px){
    .desktop-square-banner-width{
      width:48vw
    }
  }


  /**/


  @media only screen and (min-width: 300px)and (max-width: 600px){
    .desktop-empty-banner-width{
      width:70vw
    }
  }

  @media only screen and (min-width: 601px)and (max-width: 768px){
    .desktop-empty-banner-width{
      width:65vw
    }
  }

  @media only screen and (min-width: 769px)and (max-width: 900px){
    .desktop-empty-banner-width{
      width:60vw
    }
  }

  @media only screen and (min-width: 901px)and (max-width: 1040px){
    .desktop-empty-banner-width{
      width:80vw
    }
  }

  @media only screen and (min-width: 1041px)and (max-width: 1300px){
    .desktop-empty-banner-width{
      width:75vw
    }
  }

  @media only screen and (min-width: 1301px){
    .desktop-empty-banner-width{
      width:80vw
    }
  }

  @media only screen and (max-width: 768px){
    .content{
      height: 100%;
      background-color: #fff;
      margin-top: 70px;
    }
  }

  @media only screen and (min-width: 769px){
    .content{
      height: 100vh;
      background-color: #fff;
      margin-top: 70px;
    }
  }

  .custom-modal{
    width:98vw !important;
    /* display: grid; */
   /* justify-content: center; */
    top:10px;
  }

  .preview-custom-modal{
    width:70vw !important;
   display: grid;
   /* justify-content: center; */
   top:10px;
  }


  @media only screen and (min-width: 768px){
    .custom-modal-height{
      height: 100vh;
    }
  }


  @media only screen and (min-width: 200px)and (max-width: 666px){
    .tabs-vertical{
      display: none;
      background-color: #fff;
    }
    .tabs-horizontal{
      display: block;
      background-color: #fff;
      /* margin-bottom: 50px; */
    }
  }

  @media only screen and (min-width: 667px){
    .tabs-vertical{
      display: block;
      background-color: #fff;
    }
    .tabs-horizontal{
      display: none;
      background-color: #fff;
    }
  }

  
  
  @media all and (max-width: 668px){
    #mobile_icons {
        display: show;
        margin-top: -29px;
        margin-bottom: 29px;
    }
    #desktop_icons {
        display: none;
    }
}
  
@media all and (min-width: 667px){
    #mobile_icons {
        display: none;
    }
    #desktop_icons {
        display: show;
    }
}





  



  


  
  